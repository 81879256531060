import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="order-form"
export default class extends Controller {
  connect() {
    // Military form
    $('.military_form').hide();
    $('#checkbox').click(function(){
      if(this.checked){
        $('.military_form').show();
      }
      else if($(this).is(":not(:checked)")){
        $('.military_form').hide();
      }
    });

    // Login here
    $('.login').click(function(){
      $('login_form').val('');
      $('#error-message.text-danger.text-center').text('')
    });

    $('#login-here').click(function(){
      $('#login_form').val('');
      $('#user_email').val('');
      $('#user_password').val('');
      $("#error-message").text('');
      $('.error-message').text('');
    });

    // Add promocode
    $('.promocode_section_box').hide();
    $('#promo_code_checkbox').click(function(){
      if(this.checked){
        $('#order_promo_code_id').text('');
        $('.promocode_section_box').show()
        $('.promo_code_valid').html('')
        $('.promo_code_valid_success').html('')
        $('#order_promo_code_id').val('');

        $('.apply').click(function(){
          var promoCode = $('#order_promo_code_id').val();
          if(promoCode.length > 0){
            $.ajax({
              url: "/promo_code/" + promoCode,
              type: "get",
              success: function(data) {
                if(data.valid){
                  $('.promo_code_valid_btn + .btn').addClass('btn-success')
                  $('.promo_code_valid_btn + .btn').removeClass('btn-primary')
                  $('.promo_code_valid_success').html('Promo Code is valid!!')
                }else{
                  $('.promo_code_valid').html('Promo Code is not valid!!')
                  $('.promo_code_valid_btn + .btn').removeClass('btn-success')
                  $('.promo_code_valid_btn + .btn').addClass('btn-primary')
                }
              },
              error: function(data) { console.log('failure') }
            });
            $('.promo_code_valid').html('')
            $('.promo_code_valid_success').html('')
            $('.promo_code_enter').val();
          }
          else{
            $('.promo_code_valid').html('Please enter promo code');
          }
        });
      }
      else if($(this).is(":not(:checked)")){
        $('.promocode_section_box').hide()
        $('#order_promo_code_id-error').hide();
      }
    });

    //Order Form Validation
    $('.new_order').validate({
      rules: {
        // user validations
        "user[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "user[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "user[password]": {
          required: true,
          minlength: 6,
          maxlength: 15,
          passwordregex: true
        },
        // order validations
        "order[subscriber_name]": {
          required: true,
          usernameregex: true,
          minlength: 3,
          maxlength: 40
        },
        "order[email]": {
          required: true,
          email: true,
          emailregex: true
        },
        "order[password]": {
          required: true,
          minlength: 6,
          maxlength: 15,
          passwordregex: true
        },
        "order[phone]": {
          phoneregex: true,
          number: true,
          minlength: 10,
          maxlength: 10,
          required: true
        },
        "order[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "order[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "order[address]": {
          minlength: 2,
          maxlength: 100,
          required: true,
          addressregex: true
        },
        "order[zip]": {
          required: true,
          number: true,
          maxlength: 5,
          zipregex: true
        },
        "order[city]": {
          cityregex: true,
          required: true,
          minlength: 2,
          maxlength: 40
        },
        "order[state]": {
          required: true,
          cityregex: true,
          minlength: 2,
          maxlength: 40
        },
        // military validations
        "military_detail[work]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "military_detail[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "military_detail[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 40
        },
        "military_detail[state]": {
          required: true,
          cityregex: true,
          minlength: 2,
          maxlength: 40
        },
        "military_detail[zip_code]": {
          required: true,
          number: true,
          maxlength: 5,
          zipregex: true
        },
      },
      messages: {
        // User validation messages
        "user[first_name]": {
          nameregex: 'The first name can only consist of alphabets.',
          required: 'The first name is required.',
          minlength: 'The first name must be 2-40 characters long.',
          maxlength: 'The first name must be 2-40 characters long.'
        },
        "user[last_name]": {
          nameRegex: 'The last name can only consist of alphabets.',
          required: 'The last name is required.',
          minlength: 'The last name must be 2-40 characters long.',
          maxlength: 'The last name must be 2-40 characters long.'
        },
        "user[email]": {
          required: 'The email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Contains @ and .'
        },
        "user[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 6-15 characters long.',
          maxlength: 'The password must be 6-15 characters long.',
          passwordregex: 'The password must be 6-15 characters long, should contain letters and numbers only.'
        },
        // order validation messages
        "order[subscriber_name]": {
          minlength: 'The user name must be 3-40 characters long.',
          maxlength: 'The user name must be 3-40 characters long.',
          required: 'The user name is required.',
          usernameregex: 'Contains alphabets and number only.'
        },
        "order[email]": {
          required: 'The email is required.',
          email: 'The email pattern must followed.',
          emailregex: 'Contains @ and .'
        },
        "order[password]": {
          required: 'The password is required.',
          minlength: 'The password must be 6-15 characters.',
          maxlength: 'The password must be 6-15 characters.',
          passwordregex: 'The password must contain letters and numbers only.'
        },
        "order[phone]": {
          phoneregex: 'The phone contain only 10 digits.',
          number: 'Only numbers are allowed.',
          maxlength: 'Maximum 10 digits are allowed.',
          required: 'The phone number is required.'
        },
        "order[first_name]": {
          nameregex: 'The first name can only consist of alphabets.',
          required: 'The first name is required.',
          minlength: 'The first name must be 2-40 characters long.',
          maxlength: 'The first name must be 2-40 characters long.'
        },
        "order[last_name]": {
          nameRegex: 'The last name can only consist of alphabets.',
          required: 'The last name is required.',
          minlength: 'The last name must be 2-40 characters long.',
          maxlength: 'The last name must be 2-40 characters long.'
        },
        "order[address]": {
          minlength: 'The address must be 2-100 characters long.',
          maxlength: 'The address must be 2-100 characters long.',
          required: 'The address is required.',
          addressregex: 'Contains alphabets, numbers and some special characters.'
        },
        "order[zip]": {
          maxlength: 'Maximum 5 digits are allowed.',
          number: 'Only numbers are allowed.',
          zipregex: 'It contains 5 digits number.',
          required: 'The zip is required.'
        },
        "order[city]": {
          cityregex: 'City Contains alphabets, space and . only.',
          required: 'The city is required.',
          minlength: 'The city must be 2-40 characters long.',
          maxlength: 'The city must be 2-40 characters long.'
        },
        "order[state]": {
          cityregex: 'State Contains alphabets, space and . only.',
          required: 'The state is required.',
          minlength: 'The state must be 2-40 characters long.',
          maxlength: 'The state must be 2-40 characters long.'
        }
      }
    });

    $.validator.addMethod('usernameregex', function(value){
      return /^[a-zA-Z0-9 _]+$/.test(value);
    },'Contains alphabets, number and _ only.');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^\b[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(value);
    },'Contains alphabets and number only.');

    $.validator.addMethod('passwordregex', function(value, element) {
      return this.optional(element) || /^[a-zA-Z0-9]{6,15}$/.test(value);
    }, 'Must follow the password pattern');

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z ]+$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /\d{10}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('zipregex', function(value, element){
      return this.optional(element) || /\d{5}\b/g.test(value);
    },'Contains digits only');

    $.validator.addMethod('cityregex', function(value){
      return  /^[a-zA-Z .]+$/.test(value);
    },'Contains alphabets, space and . only.');

    $.validator.addMethod('addressregex', function(value){
      return  /^[a-zA-Z0-9 !@$%*,(){}\-.]+$/.test(value);
    },'Contains alphabets, space and . only.');


    // Order delivery address
    // $("#order_address").geocomplete({ details: "form" }).bind("geocode:result", function(event, result){
    //   setLocationDetails()
    // });

    // $('.bg-yellow').click(function(){
    //   $('#from-filter').val('');
    //   document.getElementById('not-found-message').innerHTML = '';
    // });
    // $('#apply_discount').click(function(){
    //   $('#from-filter').val('');
    //   $("#not-found-message").text('')
    // })
  }
}
